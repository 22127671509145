<template>
  <div>
    <div class="text-center my-3">My Profile</div>
    <b-container>
      <div
        class="d-flex justify-content-center align-items-center my-3"
        v-if="layout != 'Minimal UI - Tab bar'"
      >
        <div class="mr-2 opacity-5">My Purchase</div>
        <div class="">My Profile</div>
      </div>
      <b-card>
        <b-row class="align-items-center" no-gutters>
          <b-col cols="4"
            ><font-awesome-icon icon="qrcode" class="qr-code"
          /></b-col>
          <b-col cols="8" class="pl-2"
            ><div>Welcome, user name</div>
            <div>Member ID: xxxxxxxxx</div></b-col
          >
        </b-row>
      </b-card>
      <hr />
      <div class="content-between my-3">
        <div>My Information</div>
        <div>Edit <font-awesome-icon icon="chevron-right" class="ml-2" /></div>
      </div>
      <div class="row flex-column row-gap-lg">
        <div class="col-12">
          <div>FULL NAME</div>
          <div>user name</div>
        </div>
        <div class="col-12">
          <div>EMAIL</div>
          <div>example@mail.com</div>
        </div>
        <div class="col-12">
          <div>DATE OF BiRTH</div>
          <div>xx/xx/xxxx</div>
        </div>
        <div class="col-12">
          <div>GENDER</div>
          <div>MALE</div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <div class="content-between">
            Sign out <font-awesome-icon icon="chevron-right" />
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center footer"
        v-if="layout == 'Minimal UI - Tab bar'"
      >
        <div class="mr-2 opacity-5 text-center">
          <font-awesome-icon icon="shopping-bag" /><br />My Purchase
        </div>
        <div class="text-center">
          <font-awesome-icon icon="user" /> <br />My Profile
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    layout: { required: true },
  },
};
</script>

<style>
.qr-code {
  width: 100% !important;
  height: 100px;
}
.opacity-5 {
  opacity: 0.5;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
}
</style>
